import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor() { }

  // set access token
  SessionService_SetAccessToken(oData: any) {
    localStorage.setItem(environment.AccessToken, JSON.stringify(oData))
  }
  // get access token
  SessionService_GetAccessToken() {
    return JSON.parse(localStorage.getItem(environment.AccessToken))
  }

  // set user details
  SessionService_SetUserDetails(oData: any) {
    localStorage.setItem('userDetails', btoa(JSON.stringify(oData)));
  }

  // get user details
  SessionService_GetUserDetails() {
    const encodedData = localStorage.getItem('userDetails');
    // console.log(atob(encodedData));
    
    if (encodedData) return JSON.parse(atob(encodedData));
  }

  //***********Storing and getting Current Page  */
  SessionService_SetCurrentPage(oData: any) {
    localStorage.setItem('currentPage', JSON.stringify(oData))
  }
  SessionService_GetCurrentPage() {
    return JSON.parse(localStorage.getItem('currentPage'));
  }

  //***********Storing and getting Current Page  */
  SessionService_SetPageSize(oData: any) {
    localStorage.setItem('pageSize', JSON.stringify(oData))
  }
  SessionService_GetPageSize() {
    return JSON.parse(localStorage.getItem('pageSize'));
  }

  //set session expiry
  cSessionService_setSessionExpiry() {
    const nSessionExpiryTime: number = new Date().getTime() + 1800000;
    localStorage.setItem("ls" + environment.APP_NAME + "SessionExpiryTime", JSON.stringify(nSessionExpiryTime));
  }
  //get session expiry
  cSessionService_getSessionExpiry() {
    return JSON.parse(localStorage.getItem("ls" + environment.APP_NAME + "SessionExpiryTime"));
  }
}

