<div class="custom-dropdown manage-border" (click)="toggleDropdown()" appOutsideClick (appOutsideClick)="dropdownOpen = false">
    <div class="selected-values d-flex gap-2 flex-wrap">
      <!-- Display truncated selected value -->
      <span class="badge-EAF2F3" *ngIf="selectedItem">
        <strong>{{ items[0]?.value }}: </strong>
        <ng-container *ngIf="selectedItem?.length > maxDisplayLength">
          {{ truncate(selectedItem) }}
        </ng-container>
        <ng-container *ngIf="selectedItem?.length <= maxDisplayLength">
          {{ selectedItem }}
        </ng-container>
      </span>
      <span class="text-muted" *ngIf="!selectedItem">
        Select an option
      </span>
    </div>
  
    <!-- Dropdown List -->
    <div class="collapsed-dropdown" *ngIf="dropdownOpen">
      <div class="list-group dropdown-scroll">
        <!-- Search Bar -->
        <a href="javascript:void(0)" class="list-group-item list-group-item-action p-0">
          <input type="text" class="form-control" placeholder="Search..." [(ngModel)]="searchTerm" (keyup)="onSearchTermChange()" (click)="stopPropagation($event)" />
        </a>
  
        <!-- Individual Items -->
        <a *ngFor="let item of filteredItems" href="javascript:void(0)" class="list-group-item list-group-item-action p-0" (click)="selectItem(item)">
          <label class="model-dropdowns w-100 ms-2">
            {{ item.name }}
          </label>
        </a>
      </div>
    </div>
  </div>

