<div *ngIf="componentName=='leadership'" class="row pt-2 dropdown-area mb-3 mx-2">
    <!------------------------------- Range Calender ----------------------->
    <div class="col-lg-4 mb-2">
      <app-range-calender  (onDateChange)="onDateChange($event)" [defaultStartDate]="formattedStartDate1"
      [defaultEndDate]="formattedEndDate1"></app-range-calender>
      </div>
      <!------------------------------- Provider dropdown ----------------------->
      <div class="col-lg-4 mb-2">

         <!-- Show loading message or spinner while data is being fetched -->
         <div class="text-center mt-4" *ngIf="dropdownLoader">
          <app-skeleton-loader></app-skeleton-loader>
        </div>
      <!-- Render the custom dropdown once the data is available -->
        <app-custom-dropdown
        *ngIf="!dropdownLoader"
        [items]="providerdropdownList"
        [filterName]="filterNames"
        [maxDisplayLength]="20"
        (selectedItemsChange)="onSelectedItemsChange($event)"
        ></app-custom-dropdown>  
      </div>
</div>

<div  class="d-flex justify-content-between align-items-center mb-2 report-section">
<div>
  <small class="text-muted font-10">Last Updated</small>
  <h6>{{ lastUpdateTime  }}</h6>
</div>
<div class="">
  <button
    [class.disabled]="lifeTimeData?.length == 0"
    class="fa-solid fa-cloud-download"
    (click)="downloadCSVForRDDashboardLeadership()"
    ngbTooltip="Download CSV"
    placement="left"
    [disabled]="lifeTimeData?.length == 0"
  >
  </button>
</div>
</div>

<div class="position-relative" >
  <div *ngIf="loader">
    <app-loader></app-loader>
  </div>
  <div #tableContainer class="table-container table-responsive"  [ngClass]="{'leadership-style': componentName === 'leadership', 'disable-scroll': loader}">
    <table>
      <thead>
        <tr class="table-header1">
          <th colspan="12">appt_date_time (Year) / Actual Units</th>
        </tr>
        <tr>
          <th *ngFor="let header of columns"
           >
            {{ header.name }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of lifeTimeData">
          <td *ngFor="let header of columns" 
          [ngClass]="getColumnClass(header.name)">
            {{ row[header.value] !== null && row[header.value] !== undefined ? row[header.value] : '-' }}
          </td>
        </tr>
      </tbody>    
    </table>
    <div *ngIf="isDataInitialized && lifeTimeData?.length === 0 && !loader" class="text-center mt-2">
      No Data Found!
    </div>    
  </div>
</div>

  <div class="me-3" *ngIf="totalRecords > 0">
    <app-custom-pagination 
    [currentPage]="currentPage"
    [totalPage]="totalPages"
    [totalItems]="totalRecords"
    [numberOfItems]="end"
    [isPDM]="true"
    [start]="start"
    (pageChange)="onPageChange($event)"
    (pageSizeChange)="onPageSizeChange($event)">
</app-custom-pagination>
</div>
  
