import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SessionService } from '../session.service';
import { DataService } from '../data.service';
import { MustMatch } from '../must-match.validator';

interface SideNavToggle {
  collapsed: boolean;
  screenWidth: number;
}
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit  {
  imageUrl: string;
  userDetails: any;
  @Input() toggleSidebar: any;
  screenWidth: number = 0;
  collapsed: boolean = true;
  @Output() onToggleSidenav: EventEmitter<SideNavToggle> = new EventEmitter();
  resetPasswordForm: FormGroup;
  updateProfileForm: FormGroup;
  loader : boolean = false;
  displayErrorBlock: boolean = false;
  resMessage: { message: any; responseType: any; color: any; };
  displayNotification: boolean = false;
  searchString : string = "";
  currentUrl: string;
  pictureUrl: string;
  selectedFile: File;
  
  constructor(private route: Router, private sessionService: SessionService,private router: Router,
     private modalService: NgbModal, private formBuilder: FormBuilder, private dataService: DataService,private activatedRoute : ActivatedRoute) {
    this.userDetails = this.sessionService.SessionService_GetUserDetails()
    this.updateProfileFormControls()
    this.getUserProfile();
  }
  
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = window.innerWidth
    if (this.screenWidth <= 992) {
      this.collapsed = false;
      this.onToggleSidenav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth })
    }
    if(this.screenWidth >= 992){
      this.collapsed = true;
      this.onToggleSidenav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth })
    }
  }
  toggleCollapse() {
    this.collapsed = !this.collapsed;
    this.onToggleSidenav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth });
    this.dataService.toggleCollapse.next(!this.collapsed)
  }
  ngOnInit() {
    this.dataService.updateProfile.subscribe((res) => {
    if(res){
      this.userDetails = this.sessionService.SessionService_GetUserDetails();
    }
    })

  }

loadAvatar(check){
  check?this.imageUrl = '../../../../assets/images/profile.jpeg':this.pictureUrl = '../../../../assets/images/profile.jpeg'
}
logout() {
  localStorage.clear();
  this.route.navigateByUrl('/auth/login');
}
resetPasswordFormControls() {
  const passwordRejex = /^.{8,}$/;
  const maxPasswordLength = 24;
  this.resetPasswordForm = this.formBuilder.group({
    userId: [this.userDetails.userId],
    emailVerifyKey: [''],
    oldPassword: ['', Validators.required],
    password: ['', [ Validators.required, Validators.pattern(passwordRejex), Validators.maxLength(maxPasswordLength) ]],
    rptPassword: ['', Validators.required],
    orgName: ['']
  },
  { validator: MustMatch('password', 'rptPassword') });
}

updateProfileFormControls() {
  let textRejex = /^(?! )(.*)$/;
  this.updateProfileForm = this.formBuilder.group({
    id: [this.userDetails.id, [Validators.required]],
    firstName: [this.userDetails.firstName, [Validators.required, Validators.pattern(textRejex), Validators.minLength(2), Validators.maxLength(32)]],
    lastName: [this.userDetails.lastName, [Validators.required, Validators.pattern(textRejex), Validators.minLength(2), Validators.maxLength(32)]]
  })
  
}

resetPassword() {
  if (this.resetPasswordForm.valid) {
    this.loader = true;
    let body = this.userDetails
    body.oldPassword = this.resetPasswordForm.controls['oldPassword'].value
    body.password = this.resetPasswordForm.controls['password'].value
    body.rptPassword = this.resetPasswordForm.controls['rptPassword'].value
    this.dataService.ChangePassword(body).subscribe({
      next: (result) => {
        if (result.failed) {
          this.loader = false;
          this.displayAlertMessage('Current Password is Wrong!', 'error', 'danger');
        } else {
          this.modalService.dismissAll();
          this.resetPasswordForm.reset();
          this.loader = false;
          this.getUserProfile()
          this.displayAlertMessage('Success', 'success', 'success');
        }
      },
      error: (error) => {
        this.displayAlertMessage('Current Password is Wrong!', 'error', 'danger');
        this.loader = false;
      },
      complete: () => { },
    })
  } else {
    this.resetPasswordForm.markAllAsTouched()
  }
}

getUserProfile() {
  let body = {
    id: this.userDetails.id
  }
  this.dataService.GetUserProfile(body).subscribe({
    next: (result) => {
      if (result.status == 'success') {
        this.imageUrl = result.data.pictureUrl;
        this.pictureUrl = result.data.pictureUrl
        let userDetails = this.sessionService.SessionService_GetUserDetails();   // Update last name
        userDetails.pictureUrl = this.imageUrl;         // Update picture URL if necessary
        this.sessionService.SessionService_SetUserDetails(userDetails);
      }
    },
    error: (error) => { },
    complete: () => { }
  })


  
}

submitProfileSettingData() {
  if(this.updateProfileForm.valid){
    this.changeProfileImage()
    let body=this.userDetails
    delete body.pictureUrl
    body.firstName=this.updateProfileForm.value.firstName;
    body.lastName=this.updateProfileForm.value.lastName;
    body.fullName=body.firstName+" "+body.lastName
          this.loader = true;   
      this.dataService.SetUserProfile(body).subscribe({
        next: (result) => {
          if (result.status === 'failed') {
            this.loader = false;
            this.displayAlertMessage(result.message,'danger','danger')
          } else {
            this.displayAlertMessage(result.message,'success', 'success');
            this.sessionService.SessionService_SetUserDetails(this.userDetails)         
            this.getUserProfile()
            this.modalService.dismissAll();
            this.loader = false;
          }
        },
        error: (error) => {
          this.loader = false;
          console.error("SubmitProfileSettingData: ERROR ===>>", error);
          this.displayAlertMessage(error.message,'danger','danger')
        },
      });
  } else {
    this.updateProfileForm.markAllAsTouched();
  }
}
dismissModal() {
  this.pictureUrl=this.userDetails.pictureUrl
  this.modalService.dismissAll();
  this.updateProfileForm?.reset();
  this.resetPasswordForm?.reset();
  this.updateProfileFormControls();
  this.resetPasswordFormControls();
}


openChangePasswordModal(changePassword) {
  this.modalService.open(changePassword, { centered: true, size: 'md', backdrop: 'static' });
  this.resetPasswordFormControls()
}

openUpdateProfileModal(updateProfile) {
  this.modalService.open(updateProfile, { centered: true, size: 'md', backdrop: 'static' });
}

displayNotifications() {
  setTimeout(() => {
    this.displayNotification = !this.displayNotification
  }, 100);
}

clickOutside() {
  this.displayNotification = false;
}


ngOnDestroy(): void {
  this.modalService.dismissAll()
}

goToDashboard(){
  this.router.navigateByUrl('/dashboard');
  localStorage.removeItem('SideBarSelectedItem')
  this.dataService.goToDashboard.next(true)
}
 // --------------- Change Profile Request ----------------
 onFileSelected(event) {
  const input = event.target as HTMLInputElement;
  if (input.files && input.files[0]) {
    const file = input.files[0];
    this.selectedFile = file;
    const reader = new FileReader();

    reader.onload = (e: ProgressEvent<FileReader>) => {
      // Force the result to be treated as a string
      this.pictureUrl = e.target?.result as string;
    };

    reader.readAsDataURL(file);  // Read file as Data URL (Base64)
  }
    

}
changeProfileImage(){
  if (!this.selectedFile) {
    return;
  }
  let body = new FormData();
  body.append("file", this.selectedFile);  // Use the stored file
  body.append("folder", 'public/admins');
  body.append("functionName", "UpdateProfilePicture");
  body.append("makePublic", "false");

  this.dataService.ChangeProfileImage(body).subscribe({
    next: (result) => {
      if (result.status === 'failed') {
        console.error('Upload failed');
      } else {
        this.pictureUrl = result.folderName + "/" + result.fileName;
        this.getUserProfile();
      }
    },
    error: (error) => {
      console.error('Error occurred while uploading', error);
    },
    complete: () => {
      console.log('Profile image change completed');
    }
  });
}
 

  // ************************** ALERT MESSAGES ***************************/

displayAlertMessage(incomingMessage, incomingResponseType, incomingColor) {
  this.displayErrorBlock = true
  this.resMessage = {
    message: incomingMessage,
    responseType: incomingResponseType,
    color: incomingColor
  };
  setTimeout(() => { this.displayErrorBlock = false; }, 3000);
}

}
