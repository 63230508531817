import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
  selector: 'app-custom-dropdown',
  templateUrl: './custom-dropdown.component.html',
  styleUrls: ['./custom-dropdown.component.scss']
})
export class CustomDropdownComponent implements OnChanges {
  @Input() items: any[] = []; // Dynamic list passed from parent
  @Input() maxDisplayLength: number = 20; // Maximum length to display
  @Output() selectedItemsChange = new EventEmitter<any[]>(); // Emits the selected items
  @Input() filterName
  selectedItems: any[] = [];
  filteredItems: any[] = [];
  dropdownOpen: boolean = false;
  searchTerm: string = '';
  itemsPerPage: number = 100; // Number of items to display at once
  currentPage: number = 1;
  displayedItems: any[] = [];
ngOnChanges(): void {
    this.items=  this.items.map(item => ({ ...item, checked: true }));
    this.selectedItems = this.items.map(item => item.name);
    this.filteredItems = [...this.items]; // Initialize with full list
    this.loadItems();
}
loadItems(): void {
  const startIndex = (this.currentPage - 1) * this.itemsPerPage;
  const endIndex = this.currentPage * this.itemsPerPage;
  this.displayedItems = this.filteredItems.slice(0, endIndex); // Load items in batches
}
onScroll(event: Event) {
  const element = event.target as HTMLElement;
  const atBottom = element.scrollHeight - element.scrollTop === element.clientHeight;
  
  if (atBottom) {
    const totalPages = Math.ceil(this.filteredItems.length / this.itemsPerPage);
    if (this.currentPage < totalPages) {
      this.currentPage++;
      this.loadItems(); // Load the next set of items when scrolling
    }
  }
}
  

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
    this.currentPage = 1; // Reset page when filtering
    this.loadItems();
  }

  onSearchTermChange() {
    this.filteredItems = this.items.filter(item =>
      item.name.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
    this.currentPage = 1; // Reset page when filtering
    this.loadItems();
    
  }

  toggleAll(checked: boolean): void {
    if (this.searchTerm) {
      this.filteredItems.forEach(item => item.checked = checked);
    } else {
      this.items.forEach(item => item.checked = checked);
    }
    this.updateSelectedItems();
  }
isAllSelected(): boolean {
    if (this.searchTerm) {
      return this.filteredItems.length > 0 && this.filteredItems.every(item => item.checked);
    }
    return this.items.length > 0 && this.items.every(item => item.checked);
  }

  onItemChange(event: Event, item: any) {
    const checkbox = event.target as HTMLInputElement;
    const checked = checkbox.checked; 
    const originalItem = this.items.find(i => i.id === item.id); 
    if (originalItem) {
      originalItem.checked = checked; 
    }
    this.updateSelectedItems(); 
  }  

  updateSelectedItems() {
    this.selectedItems = this.items
  .filter(item => item.checked) 
  .map(item => item.name);  
    this.selectedItemsChange.emit(this.selectedItems);
  }

  stopPropagation(event: Event): void {
    event.stopPropagation();
  }

  truncate(text: string): string {
    return text.length > this.maxDisplayLength ? text.substring(0, this.maxDisplayLength) + '...' : text;
  }
  selectOnly(item: any): void {
    this.items.forEach(i => i.checked = (i === item));
    this.selectedItems = [item.name];
    
    this.updateSelectedItems(); 
  }
}
