<div class="row pt-2 dropdown-area mb-3 ms-2" [ngClass]="{'leadership-layout': componentName == 'leadership'}">
  
  <!-- Range Calendar -->
  <div class="col-lg-4 mb-2">
    <app-range-calender (onDateChange)="onDateChange($event)" [defaultStartDate]="formattedStartDate1"
    [defaultEndDate]="formattedEndDate1"></app-range-calender>
  </div>

  <!-- Conditionally Displayed Dropdowns -->
  <div class="col-lg-4 mb-2" *ngIf="componentName=='leadership'">
    <div class="text-center mt-4" *ngIf="dropdownLoader[ 0]">
        
      <app-skeleton-loader></app-skeleton-loader>
       </div>
    <app-custom-dropdown
    *ngIf="!dropdownLoader[0]"
    [items]="providers"
      [maxDisplayLength]="20"
      [filterName]="filterNames[0]"
      (selectedItemsChange)="onSelectedItemsChange($event, true)"
    ></app-custom-dropdown>
  </div>

  <div class="col-lg-4 mb-2" *ngIf="componentName=='leadership'">
    <div class="text-center mt-4" *ngIf="dropdownLoader[ 1]">
        
      <app-skeleton-loader></app-skeleton-loader>
       </div>
    <app-custom-dropdown
    *ngIf="!dropdownLoader[1]"
    [items]="appointment"
      [maxDisplayLength]="20"
      [filterName]="filterNames[1]"
      (selectedItemsChange)="onSelectedItemsChange($event, false)"
    ></app-custom-dropdown>
  </div>
</div>

<div class="row position-relative">
    <div class="col-md-7">
        <div class="d-flex justify-content-between align-items-center report-section">
          <div class=" mb-2">   
            <small class="text-muted font-10">Last Updated</small>
            <div>{{ lastUpdateTime }}</div>
        </div>
          <div  class=" upload-csv2 ">
            <button
            [class.disabled]="lifeTimeData?.length == 0"
            (click)="exportRDvsNonRDCSV()"
            class="fa-solid fa-cloud-download"
            ngbTooltip="Download CSV"
            placement="left"
            [disabled]="lifeTimeData?.length == 0"
        >
        </button>
          </div>
      
        </div>

        <div class="position-relative">
          <div *ngIf="loader[0]">
            <app-loader></app-loader>
          </div>
          <div #tableContainer1 class="table-container table-responsive" [ngClass]="{'disable-scroll' : loader[0]}">
            <table>
              <thead>
                <tr>
                  <th *ngFor="let header of columns" [ngClass]="getColumnClass(header.name)">
                    {{ header.name }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of lifeTimeData">
                  <td *ngFor="let header of columns" [ngClass]="getColumnClass(header.name)">
                    {{ row[header.value] !== null && row[header.value] !== undefined ? row[header.value] : '-' }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div *ngIf="isInitialized1 && lifeTimeData?.length === 0 && !loader[0]" class="text-center mt-2">
              No Data Found!
            </div>
          </div>
        </div>
      
      <!-- Pagination for Table 1 -->
      <div class="me-3"  *ngIf="lifeTimeData?.length > 0">
        <app-custom-pagination 
          [currentPage]="currentPage"
          [totalPage]="totalPages"
          [totalItems]="totalRecords"
          [numberOfItems]="end"
          [isPDM]="true"
          [start]="start"
          (pageChange)="onPageChange($event)"
          (pageSizeChange)="onPageSizeChange($event)">
        </app-custom-pagination>
      </div>
    </div>
  
    <!-- Table for columns1 (col-md-4) -->
    
    <div class="col-md-5">
      <div class="d-flex justify-content-between align-items-center report-section">
        <div class=" mb-2">   
          <small class="text-muted font-10">Last Updated</small>
          <div>{{ lastUpdateTime }}</div>
      </div>
        <div  class=" upload-csv2 ">
          <button
          [class.disabled]="lifeTimeData1?.length == 0"
          (click)="exportRDvsNonRDCSVTable2()"
          class="fa-solid fa-cloud-download"
          ngbTooltip="Download CSV"
          placement="left"
          [disabled]="lifeTimeData1?.length == 0"
      >
      </button>
        </div>
      </div>

      <div class="position-relative" >
        <div *ngIf="loader[1]">
          <app-loader></app-loader>
        </div>
        <div #tableContainer2 class="table-container table-responsive" [ngClass]="{'disable-scroll': loader[1]}">
          <table>
            <thead>
              <tr>
                <th *ngFor="let header of columns1" [ngClass]="getColumnClass(header.name)">
                  {{ header.name }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of lifeTimeData1">
                <td *ngFor="let header of columns1" [ngClass]="getColumnClass(header.name)">
                  {{ row[header.value] !== null && row[header.value] !== undefined ? row[header.value] : '-' }}
                </td>
              </tr>
            </tbody>
          </table>
          <div *ngIf="isInitialized2 && lifeTimeData1?.length === 0 && !loader[1]" class="text-center mt-2">
            No Data Found!
          </div>        
        </div>
      </div>
      
      <!-- Pagination for Table 2 -->
      <div class="me-3" *ngIf="lifeTimeData1?.length > 0">
        <app-custom-pagination 
          [currentPage]="currentPage1"
          [totalPage]="totalPages1"
          [totalItems]="totalRecords1"
          [numberOfItems]="end1"
          [isPDM]="true"
          [start]="start1"
          (pageChange)="onPageChangeTable2($event)"
          (pageSizeChange)="onPageSizeChangeTable2($event)">
        </app-custom-pagination>
      </div>
    </div>
  </div>
  
