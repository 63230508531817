import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './header/header.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AlertsComponent } from './alerts/alerts.component';
import { LoaderComponent } from './loader/loader.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { CustomPaginationComponent } from './custom-pagination/custom-pagination.component';
import { HeadersInterceptor } from 'src/interceptors/headers.interceptor';
import { AppOutsideClickDirective } from './directives/app-outside-click.directive';
import { RangeCalenderComponent } from './range-calender/range-calender.component';
import { NgxDaterangepickerBootstrapModule, NgxDaterangepickerLocaleService } from 'ngx-daterangepicker-bootstrap';
import { LifetimeUnitReportComponent } from './shared-report/lifetime-unit-report/lifetime-unit-report.component';
import { CustomDropdownComponent } from './custom-dropdown/custom-dropdown.component';
import { SkeletonLoaderComponent } from './skeleton-loader/skeleton-loader.component';
import { CancellationRateReportComponent } from './shared-report/cancellation-rate-report/cancellation-rate-report.component';
import { CustomDropdownSingleComponent } from './custom-dropdown-single/custom-dropdown-single.component';
import { PatientsNotScheduledReportComponent } from './shared-report/patients-not-scheduled-report/patients-not-scheduled-report.component';
import { GdmPatientsReportComponent } from './shared-report/gdm-patients-report/gdm-patients-report.component';



@NgModule({
  declarations: [
    HeaderComponent,
    AlertsComponent,
    LoaderComponent,
    SidebarComponent,
    CustomPaginationComponent,
    AppOutsideClickDirective,
    RangeCalenderComponent,
    LifetimeUnitReportComponent,

    CustomDropdownComponent,
    SkeletonLoaderComponent,
    CancellationRateReportComponent,
    CustomDropdownSingleComponent,
    PatientsNotScheduledReportComponent,
    GdmPatientsReportComponent,

  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NgbModule,
    RouterModule,
    NgxDaterangepickerBootstrapModule.forRoot(),
  ],
  providers: [
    HeadersInterceptor,
    NgxDaterangepickerLocaleService
  ],
  exports:[ HeaderComponent,AlertsComponent,LoaderComponent,SidebarComponent,CustomDropdownSingleComponent,LifetimeUnitReportComponent,GdmPatientsReportComponent,PatientsNotScheduledReportComponent,SkeletonLoaderComponent,CancellationRateReportComponent,CustomPaginationComponent,CustomDropdownComponent,AppOutsideClickDirective,RangeCalenderComponent ]
})
export class SharedModule { }
