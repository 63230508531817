import { Component, Input, OnInit, ViewChild } from '@angular/core';
import * as dayjs from 'dayjs';
import { DataService } from 'src/app/shared/data.service';

@Component({
  selector: 'app-lifetime-unit-report',
  templateUrl: './lifetime-unit-report.component.html',
  styleUrls: ['./lifetime-unit-report.component.scss']
})
export class LifetimeUnitReportComponent {
 @Input() columns
 @Input() componentName: any;
  providerdropdownList = [];
  selectedItems = [];
  providerselectedItems = [];
  filteredProvider: any[];
  isInitialLoad: boolean = false; 
  searchTerm: any;
  toggleProviderDropdown: boolean = false;
  formattedStartDate1 = dayjs('2019-01-01');
  formattedEndDate1 = dayjs(); // This will give you the current date
  formattedStartDate = '2019-01-01';
   formattedEndDate = new Date().toISOString().split('T')[0];
  paginatedData: any[] = [];
  currentPage: number = 1;
  itemsPerPage: number = 100;
  totalRecords: number;
  totalPages: number;
  start: number = 1;
  end: number = 100;
  lifeTimeData: any[] = [];
  dropdownLoader: boolean = false;
  loader:boolean = false;
  lastUpdateTime: any;
  filterNames:string = 'Provider';
  isDataInitialized = false; 
  @ViewChild('tableContainer') tableContainer: any;

constructor(private dataService: DataService) { }
ngOnInit(): void {
  if (this.componentName === 'leadership') {
      this.getProvider();
    
  } else {
    this.fetchPaginatedData();
  }
}  

fetchPaginatedData() {  
  this.loader = true;
  this.isDataInitialized = false; // Reset initialization flag when starting data fetch
  let body;
  let apiCall;

  // Choose the API call based on componentName
  if (this.componentName === 'leadership') {
    body = {
      startDate: this.formattedStartDate + ' 00:00:00',
      endDate: this.formattedEndDate + ' 23:59:59',
      start: this.start.toString(),
      size: this.itemsPerPage.toString(),
      providers: this.providerselectedItems,
    };
    apiCall = this.dataService.GetRDDasshboardLeadershipData(body);
  } else {
    body = {
      start: this.start.toString(),
      size: this.itemsPerPage.toString(),
    };
    apiCall = this.dataService.GetRDDasshboardData(body);
  }

  // Common result handling logic
  apiCall.subscribe({
    next: (result) => {
      this.loader = false;
      this.isDataInitialized = true; // Mark data as initialized
      if (result.status === 'failed') {
        console.error('Failed to fetch RDD Dashboard data:', result.message);
      } else {
        this.lifeTimeData = result?.data?.data || [];
        this.totalRecords = result?.data?.total || this.lifeTimeData.length;
        this.totalPages = Math.ceil(this.totalRecords / this.itemsPerPage);
        let timestamp = result?.data?.lastUpdateTime?.updatedAt;
        this.lastUpdateTime = timestamp ? new Date(timestamp).toLocaleString() : 'No updates available';
        this.updateEnd();
      }
    },
    error: (error) => {
      this.loader = false;
      this.isDataInitialized = true; // Mark as initialized on error
      console.error('Error fetching RDD Dashboard data:', error);
    }
  });
}

resetScroll1() {
  if (this.tableContainer) {
    this.tableContainer.nativeElement.scrollTop = 0;
    this.tableContainer.nativeElement.scrollLeft = 0;
  }
}

getProvider() {
  this.dropdownLoader = true;
  let body = {
    startDate: this.formattedStartDate + ' 00:00:00',
    endDate: this.formattedEndDate + ' 23:59:59',
  }
  this.dataService.GetAllProviders(body).subscribe({
    next: (result) => {
      this.dropdownLoader = false;
      if (result.status == 'failed') {
      } else {
        this.providerdropdownList = result.data.map(item => ({ ...item, checked: true }));
        this.providerselectedItems = this.providerdropdownList.map(item => item.name)
          this.fetchPaginatedData();
      }
    },
    error: (error) => { 
      this.dropdownLoader = false;
      console.log(error);
    }
  })
}

downloadCSVForRDDashboardLeadership() {
  let body: any;
  let apiCall;

  // Choose the API call and body structure based on componentName
  if (this.componentName === 'leadership') {
    body = {
      startDate: this.formattedStartDate + ' 00:00:00',
      endDate: this.formattedEndDate + ' 23:59:59',
      start: this.start.toString(),
      size: this.itemsPerPage.toString(),
      providers: this.providerselectedItems,
    };
    apiCall = this.dataService.ExportRDDasshboardLeadershipData(body);
  } else {
    body = {
      start: this.start.toString(),
      size: this.itemsPerPage.toString(),
    };
    apiCall = this.dataService.ExportRDDasshboardData(body);
  }

  apiCall.subscribe({
    next: (result) => {
      if (result && result.status === 'success') {
        const fileUrl = result?.data?.url;

        if (fileUrl) {
          const a = document.createElement('a');
          a.href = fileUrl;
          a.download = this.componentName === 'leadership' ? 'rdd_dashboard_leadership.csv' : 'rdd_dashboard.csv';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          console.error('No URL provided for the CSV download');
        }
      } else {
        console.error('Failed to download CSV:', result?.message);
      }
    },
    error: (error) => {
      console.error('Error downloading CSV:', error);
    }
  });
}

onDateChange(event: any) {
  if (this.isInitialLoad) {
    this.formattedStartDate = event.startDate;
    this.formattedEndDate = event.endDate;
    this.start = 1;
    this.currentPage = 1;
    this.getProvider();
  }
  this.isInitialLoad = true;
  this.resetScroll1();
}

updatePaginatedData() {
  this.start = (this.currentPage - 1) * this.itemsPerPage + 1;
  this.end = Math.min(this.start + this.itemsPerPage - 1, this.totalRecords);
  this.fetchPaginatedData();
}

onPageChange(newPage: number) {
  if (newPage >= 1 && newPage <= this.totalPages) {
    this.currentPage = newPage;
    this.updatePaginatedData(); 
    this.resetScroll1();
  }
}

onPageSizeChange(newPageSize: number) {
  this.itemsPerPage = newPageSize;  
  this.currentPage = 1;             
  this.totalPages = Math.ceil(this.totalRecords / this.itemsPerPage);
  this.updatePaginatedData();  
}
updateEnd() {
  this.end = Math.min(this.currentPage * this.itemsPerPage, this.totalRecords);
}

onSelectedItemsChange(selectedItems: any[]) {
  this.providerselectedItems = selectedItems
  this.start = 1
  this.currentPage = 1
  this.fetchPaginatedData(); 
  this.resetScroll1();
}


getColumnClass(columnName: string): string {
  if (columnName === 'Provider') {
    return 'text-left';
  } else if (columnName === 'Grand Total') {
    return 'fw-700 text-left';
  } else {
    return 'text-left';
  }
}

}
