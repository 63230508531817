import { Component, Input, OnInit, ViewChild } from '@angular/core';
import * as dayjs from 'dayjs';
import { DataService } from 'src/app/shared/data.service';

@Component({
  selector: 'app-cancellation-rate-report',
  templateUrl: './cancellation-rate-report.component.html',
  styleUrls: ['./cancellation-rate-report.component.scss']
})
export class CancellationRateReportComponent {
  @Input() columns
  @Input() componentName
  formattedStartDate1 = dayjs(new Date().getFullYear()+'-01-01')
  formattedEndDate1 = dayjs();;  
  formattedStartDate = new Date().getFullYear()+'-01-01'
  formattedEndDate = new Date().toISOString().split('T')[0]; 
  paginatedData: any[] = [];
  currentPage: number = 1;
  itemsPerPage: number = 100;
  totalRecords: number;
  totalPages: number;
  start: number = 1;
  end: number = 100;
  lifeTimeData: any[] = [];
  isInitialLoad: boolean = false; 
  selectedServiceTypes: string[] = []; 
  providers: any;
  selectedProviders: any;
  dropdownLoader: boolean[] = [true, true, true];
  lastUpdateTime: string;
  loader: boolean = false;
  filterNames:string[] = ['Provider', 'Service Type', 'Billing Type']
  serviceTypeDropdown: boolean = false;
serviceType: any = [];
selectedBillingType: any = [];
billingType:any = [];
isInitialized = false;
@ViewChild('tableContainer') tableContainer: any;

  constructor(private dataService: DataService) { }
  
  ngOnInit(): void {
  
  if(this.componentName=='leadership')
  {
    this.fetchProviders();
    this.fetchServiceTypes();
    this.GetBillingType()
  }
  else{
    this.getCancelletionDashboardData()
  }
 
  }

  resetScroll1() {
    if (this.tableContainer) {
      this.tableContainer.nativeElement.scrollTop = 0;
      this.tableContainer.nativeElement.scrollLeft = 0;
    }
  }

  getCancelletionDashboardData() {
    this.loader = true;
    let body;
    let apiCall;
  
    if (this.componentName === 'leadership') {
      body = {
        startDate: this.formattedStartDate + ' 00:00:00',
        endDate: this.formattedEndDate + ' 23:59:59',
        start: this.start.toString(),
        size: this.itemsPerPage.toString(),
        providers: this.selectedProviders,
        serviceType: this.selectedServiceTypes,
        billingType: this.selectedBillingType,
      };
      apiCall = this.dataService.GetRDDashboardLeadershipCR(body);
    } else {
      body = {
        startDate: this.formattedStartDate + ' 00:00:00',
        endDate: this.formattedEndDate + ' 23:59:59',
        start: this.start.toString(),
        size: this.itemsPerPage.toString(),
      };
      apiCall = this.dataService.GetRDDashboardCR(body);
    }
  
    apiCall.subscribe({
      next: (result) => {
        this.loader = false;
        this.isInitialized = true; // Mark the request as initialized
        if (result.status === 'failed') {
          console.error('Failed to fetch billing data:', result.message);
        } else {
          this.lifeTimeData = result?.data?.data || [];
          this.totalRecords = result?.data?.total || this.lifeTimeData.length;
          this.totalPages = Math.ceil(this.totalRecords / this.itemsPerPage);
          let timestamp = result?.data?.lastUpdateTime?.updatedAt;
          this.lastUpdateTime = timestamp ? new Date(timestamp).toLocaleString() : 'No updates available';
          this.updateEnd();
        }
      },
      error: (error) => {
        this.loader = false;
        this.isInitialized = true; // Mark the request as initialized on error
        console.error('Error fetching billing dashboard data:', error);
      }
    });
  }

  downloadCSVForRDDashboardCR() {
    let body: any;
    let apiCall;
  
    // Choose the API call and body structure based on componentName
    if (this.componentName === 'leadership') {
      body = {
        startDate: this.formattedStartDate + ' 00:00:00',
        endDate: this.formattedEndDate + ' 23:59:59',
        start: this.start.toString(),
        size: this.itemsPerPage.toString(),
        providers: this.selectedProviders,
        serviceType:this.selectedServiceTypes,
        billingType: this.selectedBillingType, 
      };
      apiCall = this.dataService.ExportRDDashboardLeadershipCR(body);
    } else {
      body = {
        startDate: this.formattedStartDate + ' 00:00:00',
        endDate: this.formattedEndDate + ' 23:59:59',
        start: this.start.toString(),
        size: this.itemsPerPage.toString(),
      };
      apiCall = this.dataService.ExportRDDashboardCR(body);
    }
  
    apiCall.subscribe({
      next: (result) => {
        if (result && result.status === 'success') {
          const fileUrl = result?.data?.url;
  
          if (fileUrl) {
            const a = document.createElement('a');
            a.href = fileUrl;
            a.download = this.componentName === 'leadership' ? 'rdd_dashboard_leadership.csv' : 'rdd_dashboard.csv';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          } else {
            console.error('No URL provided for the CSV download');
          }
        } else {
          console.error('Failed to download CSV:', result?.message);
        }
      },
      error: (error) => {
        console.error('Error downloading CSV:', error);
      }
    });
  }
  

  fetchProviders(): void {
    let body = {
      startDate: this.formattedStartDate + ' 00:00:00',
      endDate: this.formattedEndDate + ' 23:59:59',
    };
  
    this.dataService.GetProviderCancellation(body).subscribe({
      next: (result) => {
        this.dropdownLoader[0] = false;
        if (result.status === 'failed') {
          console.error('Failed to fetch provider data:', result.message);
        } else {
          this.providers = result.data.map(item => ({ ...item, checked: true }));
          this.selectedProviders = this.providers.map(item => item.name);
    
        }
      },
      error: (error) => {
        this.dropdownLoader[0] = false;
        console.error('Error fetching provider data:', error);
      }
    });
  }
  fetchServiceTypes(): void {
    let body = {
      startDate: this.formattedStartDate + ' 00:00:00',
      endDate: this.formattedEndDate + ' 23:59:59',
    };
  
    this.dataService.GetAppointmentUnitsServiceTypes(body).subscribe({
      next: (result) => {
        this.dropdownLoader[1] = false; 
        if (result.status === 'failed') {
          console.error('Failed to fetch data:', result.message);

        } else {
          this.serviceType = result.data.map(item => ({ ...item, checked: true }));
          this.selectedServiceTypes = this.serviceType.map(item => item.name); 
      
        
        }
      },
      error: (error) => {
        console.error('Error fetching billing dashboard data:', error);
        this.dropdownLoader[1] = false; 
      }
    });
  }

  GetBillingType() {
    let body = {
      startDate: this.formattedStartDate + ' 00:00:00',
      endDate: this.formattedEndDate + ' 23:59:59',
    };
    this.dataService.GetAppointmentUnitsBillingTypes(body).subscribe({
      next: (result) => {
        this.dropdownLoader[2] = false;
        if (result.status === 'failed') {
          console.error('Failed to fetch data:', result.message);
        } else {
          this.billingType = result.data.map(item => ({ ...item, checked: true }));
          this.selectedBillingType = this.billingType.map(item => item.name);
          setTimeout(() => {
            this.getCancelletionDashboardData()
          }, 500)
    
        }
      },
      error: (error) => {
        this.dropdownLoader[2] = false;
        console.error('Error fetching billing dashboard data:', error);
      }
    });
  }
  
  

  onDateChange(event: any) {
    if (this.isInitialLoad) {
      this.formattedStartDate = event.startDate;
      this.formattedEndDate = event.endDate;
      this.start = 1;
      this.currentPage = 1;
      if (this.componentName === 'leadership') {
        this.fetchProviders();
        this.fetchServiceTypes();
        this.GetBillingType();
      }
      else{
        this.getCancelletionDashboardData()
      }
    }
    this.isInitialLoad = true;
    this.resetScroll1();
  }
  
  
  updatePaginatedData() {
    this.start = (this.currentPage - 1) * this.itemsPerPage + 1;
    this.end = Math.min(this.start + this.itemsPerPage - 1, this.totalRecords);
    this.getCancelletionDashboardData()
  }
  
  onPageChange(newPage: number) {
    if (newPage >= 1 && newPage <= this.totalPages) {
      this.currentPage = newPage;
      this.updatePaginatedData(); 
      this.resetScroll1();
    }
  }
  
  onPageSizeChange(newPageSize: number) {
    this.itemsPerPage = newPageSize;  
    this.currentPage = 1;             
    this.totalPages = Math.ceil(this.totalRecords / this.itemsPerPage);
    this.updatePaginatedData();  
  }
  updateEnd() {
    this.end = Math.min(this.currentPage * this.itemsPerPage, this.totalRecords);
  }
 
  onSelectedItemsChange(selectedItems: any[], check) {
    if(check == 1){
     this.selectedProviders = selectedItems
    }
    else if(check == 2){
      this.selectedServiceTypes = selectedItems;
    }
    else if (check == 3) {
      this.selectedBillingType = selectedItems;
    }
  
 this.start = 1;
 this.currentPage = 1;
 this.updateEnd();
 this.getCancelletionDashboardData();
 this.resetScroll1();
  }

  getColumnClass(columnName: string): string {
    if (columnName === 'Year Week') {
      return 'text-left';
    } else if (columnName === 'Cancelled') {
      return ' text-left';
    } else {
      return 'text-left';
    }
  }  
}
