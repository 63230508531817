<div class="row pt-2 dropdown-area mb-3  mx-2">
    <!------------------------------- Range Calender ----------------------->

    <div   [ngClass]="{
      'col-xl-4': componentName !== 'leadership',
      'col-xl-3': componentName === 'leadership',
      'col-lg-6': true,
      'mb-2': true
    }">
    <app-range-calender  (onDateChange)="onDateChange($event)" [defaultStartDate]="formattedStartDate1"
    [defaultEndDate]="formattedEndDate1"></app-range-calender>
    </div>

    <!------------------------------- Provider dropdown ----------------------->

    <div *ngIf="componentName=='leadership'" class="col-xl-3 col-lg-6 mb-2">
        <div class="text-center mt-4" *ngIf="dropdownLoader[0]">
        
         <app-skeleton-loader></app-skeleton-loader>
          </div>
        <app-custom-dropdown
        *ngIf="!dropdownLoader[0]"
        [items]="providers"
        [filterName]="filterNames[0]"
          [maxDisplayLength]="20"
          (selectedItemsChange)="onSelectedItemsChange($event, 1)"
        ></app-custom-dropdown>
      </div>
         <!------------------------------- service_type dropdown ----------------------->
         <div *ngIf="componentName=='leadership'" class="col-xl-3 col-lg-6 mb-2">
          <!-- Show loading message or spinner while data is being fetched -->
          <div class="text-center  mt-4" *ngIf="dropdownLoader[1]">
              <app-skeleton-loader></app-skeleton-loader>
            </div>
        
          <!-- Render the custom dropdown once the data is available -->
          <app-custom-dropdown
            *ngIf="!dropdownLoader[1]"
            [items]="serviceType"
            [filterName]="filterNames[1]"
            [maxDisplayLength]="20"
            (selectedItemsChange)="onSelectedItemsChange($event, 2)"
          ></app-custom-dropdown>
        </div>      

      <!------------------------------- billing_type dropdown ----------------------->


        <div *ngIf="componentName=='leadership'" class="col-xl-3 col-lg-6 mb-2">
          <!-- Show loading message or spinner while data is being fetched -->
          <div class="text-center  mt-4" *ngIf="dropdownLoader[2]">
              <app-skeleton-loader></app-skeleton-loader>
            </div>
        
          <!-- Render the custom dropdown once the data is available -->
          <app-custom-dropdown
            *ngIf="!dropdownLoader[2]"
            [items]="billingType"
            [filterName]="filterNames[2]"
            [maxDisplayLength]="20"
            (selectedItemsChange)="onSelectedItemsChange($event, 3)"
          ></app-custom-dropdown>
        </div> 
  </div>
  <div class="d-flex justify-content-between align-items-center mb-2 report-section"> 
        <div>   
            <small class="text-muted font-10">Last Updated</small>
            <div>{{ lastUpdateTime }}</div>
        </div>
    <div >
        <button
            [class.disabled]="lifeTimeData?.length == 0"
            class="fa-solid fa-cloud-download"
            ngbTooltip="Download CSV"
            (click)="downloadCSVForRDDashboardCR()"
            placement="left"
            [disabled]="lifeTimeData?.length == 0"
        >
        </button>
    </div>
</div>

<div class="position-relative" >
  <div *ngIf="loader">
    <app-loader></app-loader>
  </div>
  <div #tableContainer class="table-container table-responsive" [ngClass]="{'leadership-style': componentName === 'leadership', 'disable-scroll' : loader}">
    <table>
        <thead>
          <tr class="table-header1">
            <th class="text-end" colspan="8">status / scheduled_appointments</th>
          </tr>
          <tr>
            <th *ngFor="let header of columns"
                [ngClass]="getColumnClass(header.name)">
              {{ header.name }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of lifeTimeData">
            <td *ngFor="let header of columns" 
                [ngClass]="getColumnClass(header.name)">
              {{ row[header.value] !== null && row[header.value] !== undefined ? row[header.value] : '-' }}
            </td>
          </tr>
        </tbody>
      </table>      
      <div *ngIf="isInitialized && lifeTimeData?.length === 0 && !loader" class="text-center mt-2">
        No Data Found!
      </div>      
  </div>
</div>


    <div class="me-3" *ngIf="totalRecords > 0">
      <app-custom-pagination 
      [currentPage]="currentPage"
      [totalPage]="totalPages"
      [totalItems]="totalRecords"
      [numberOfItems]="end"
      [isPDM]="true"
      [start]="start"
      (pageChange)="onPageChange($event)"
      (pageSizeChange)="onPageSizeChange($event)">
  </app-custom-pagination>
  </div>
