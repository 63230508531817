import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BlankComponent } from './layouts/blank/blank.component';
import { FullComponent } from './layouts/full/full.component';
import { canActivateTeam } from './guards/auth.guard';
import { UserRoleGuard } from './guards/user-role.guard';

const routes: Routes = [
  { path: "", redirectTo: "/auth/login", pathMatch: "full" },
  {
    path: "",
    component: BlankComponent,
    children: [
      { path: "", redirectTo: "/auth/login", pathMatch: "full" },
      {
        path: "auth",
        loadChildren: () =>
          import("../app/modules/authentication/authentication.module").then(
            (m) => m.AuthenticationModule
          ),
      },
    ],
  },
  {
    path: "",
    component: FullComponent, canActivate: [canActivateTeam],
    children: [
      {
        path: "admin", canActivate: [UserRoleGuard],
        loadChildren: () =>
          import("./modules/admin/admin.module").then((m) => m.AdminModule),
      },
      {
        path: "dashboard",
        loadChildren: () =>
          import("./modules/dashboard/dashboard.module").then((m) => m.DashboardModule),
      },
      {
        path: "billing-dashboard-reports",
        loadChildren: () =>
          import("./modules/billing-dashboard-reports/billing-dashboard-reports.module").then((m) => m.BillingDashboardReportsModule),
      },
      {
        path: "clinical-data-review",
        loadChildren: () =>
          import("./modules/clinical-data-review/clinical-data-review.module").then((m) => m.ClinicalDataReviewModule),
      },
      {
        path: "metrics-compliance",
        loadChildren: () =>
          import("./modules/metrics-compliance/metrics-compliance.module").then((m) => m.MetricsComplianceModule),
      },
      {
        path: "operations-analysis",
        loadChildren: () =>
          import("./modules/operations-analysis/operations-analysis.module").then((m) => m.OperationsAnalysisModule),
      },
      {
        path: "rd-dashboard-leadership",
        loadChildren: () =>
          import("./modules/rd-dashboard-leadership/rd-dashboard-leadership.module").then((m) => m.RdDashboardLeadershipModule),
      },
      {
        path: "simplex-billing-ops-compliance",
        loadChildren: () =>
          import("./modules/simplex-billing-ops-compliance/simplex-billing-ops-compliance.module").then((m) => m.SimplexBillingOpsComplianceModule),
      },
      {
        path: "simplex-health-general-dashboard",
        loadChildren: () =>
          import("./modules/simplex-health-general-dashboard/simplex-health-general-dashboard.module").then((m) => m.SimplexHealthGeneralDashboardModule),
      },
      {
        path: "tellescope",
        loadChildren: () =>
          import("./modules/tellescope/tellescope.module").then((m) => m.TellescopeModule),
      },
      
    ],
  },
  {
    path: "**",
    redirectTo: "auth/login",
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
