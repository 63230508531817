<div class="sidenav" [ngClass]="collapsed ? 'sidenav-collapsed' : ''">
  <!-- <div class="text-end p-2 collapse-icon" *ngIf="displayCollapseAll" [class.d-none]="!collapsed" (click)="collapseSideBar(navData)">
      <div class="angle-position position-relative"><span class="fa-solid fa-angle-up angle-icon"></span></div>
    </div> -->
  <!-- {{navData | json}} -->

  <ul class="sidenav-nav p-0 custom-scroller">
    <ng-container *ngFor="let item of navData">
      <ng-container *ngTemplateOutlet="recursiveItem; context: { $implicit: item }"></ng-container>
    </ng-container>
  </ul>
</div>

<ng-template #recursiveItem let-item>
  <li class="sidenav-nav-item">
    <a href="javascript:void(0)" class="sidenav-nav-link"
      [routerLink]="item.routerLink !== '' ? [item.routerLink] : null" routerLinkActive="active"
      [ngClass]="{ 'highlight-active':!item.items && isActiveRoute(item) }"
      (click)="toggleSubItems(item)">
      <span class="fa-solid fa-circle" *ngIf="item.routerLink !== '' && item.label !=='Admin'"></span>
      <span class="sidenav-link-text px-3" #textElement tooltipClass="custom-tooltip"
        [ngbTooltip]="isTextOverflowing(textElement) ? item.label : null" placement="right">
        {{ item.label }}
      </span>

      <i *ngIf="item.items" class="menu-collapse-icon fa-solid"
        [ngClass]="!item.isOpen ? 'fa-angle-right' : 'fa-angle-down'"></i>
    </a>

    <ul *ngIf="item.isOpen && item.items" class="sublevel-nav" [@submenu]="item.isOpen ? {value:'visible', params: {transitionParams:'400ms cubic-bezier(0.86,0,0.07,1)',height:'*'}} :
            {value:'visible', params: {transitionParams:'400ms cubic-bezier(0.86,0,0.07,1)',height:'0'}}">
      <ng-container *ngFor="let subitem of item.items">
        <ng-container *ngTemplateOutlet="recursiveItem; context: { $implicit: subitem }"></ng-container>
      </ng-container>
    </ul>
  </li>

</ng-template>