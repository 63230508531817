import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
  selector: 'app-custom-dropdown-single',
  templateUrl: './custom-dropdown-single.component.html',
  styleUrls: ['./custom-dropdown-single.component.scss']
})
export class CustomDropdownSingleComponent implements OnChanges {
  @Input() items: any[] = []; // Dynamic list passed from parent
  @Input() maxDisplayLength: number = 20; // Maximum length to display
  @Output() selectedItemSingleChange = new EventEmitter<any>(); // Emits the selected item

  selectedItem: any = null;
  filteredItems: any[] = [];
  dropdownOpen: boolean = false;
  searchTerm: string = '';

  ngOnChanges(): void { 
    this.filteredItems = [...this.items]; 
    this.selectedItem=['Monthly']// Initialize with full list
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  onSearchTermChange() {
    this.filteredItems = this.items.filter(item =>
      item.name.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }

  selectItem(item: any): void {
   
    
    this.selectedItem = [item.name]; // Set the selected item
    console.log(this.selectedItem);
    this.selectedItemSingleChange.emit(this.selectedItem); // Emit the selected item
    this.dropdownOpen = false; // Close the dropdown
  }

  stopPropagation(event: Event): void {
    event.stopPropagation();
  }

  truncate(text: string): string {
    return text.length > this.maxDisplayLength ? text.substring(0, this.maxDisplayLength) + '...' : text;
  }
}
