import { Component, Input, ViewChild } from '@angular/core';
import { DataService } from '../../data.service';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-gdm-patients-report',
  templateUrl: './gdm-patients-report.component.html',
  styleUrls: ['./gdm-patients-report.component.scss']
})
export class GdmPatientsReportComponent {
  @Input() columns
  @Input() columns1
  @Input() componentName
   formattedStartDate1 = dayjs('2021-01-01');
  formattedEndDate1 = dayjs(); // This will give you the current date
  formattedStartDate = '2021-01-01';
   formattedEndDate = new Date().toISOString().split('T')[0];
  paginatedData: any[] = [];
  currentPage: number = 1;
  itemsPerPage: number = 100;
  totalRecords: number;
  totalPages: number;
  start: number = 1;
  end: number = 100;
  isInitialLoad: boolean = false;
  loader:boolean[] = [false, false];
  lastUpdateTime: string;
  lifeTimeData: any[] = [ ];
  dropdownLoader: boolean[] = [true, true];
  providers: any;
  appointment:any
  selectedProviders: any;
  selectedAppointmentDate: string[] = []; 
  selectedItems = [];
  currentPage1: number = 1;
  totalPages1: number = 0;
  totalRecords1: number = 0;
  start1: number = 1;
  end1: number = 100;
  itemsPerPage1: number = 100;
  filterNames:string[] = ['Provider', 'Next Appointment Date'];
  isInitialized1 = false; 
isInitialized2 = false;
@ViewChild('tableContainer1') tableContainer1: any;
@ViewChild('tableContainer2') tableContainer2: any;

  lifeTimeData1: any[] = [ ];
  constructor(private dataService: DataService) { }
  ngOnInit(): void {
    if(this.componentName=='leadership')
    {
      this.fetchProviders(false);
      this.fetchNextAppointmentDate()
    
    }
    else
    {
      this.getGDMPTable1Data();
      this.getGDMPTable2Data();
    }

  }
  getGDMPTable1Data() {
    this.loader[0] = true;
    let body;
    let apiCall;
  
    if (this.componentName === 'leadership') {
      body = {
        startDate: this.formattedStartDate + ' 00:00:00',
        endDate: this.formattedEndDate + ' 23:59:59',
        providers: this.selectedProviders,
        appointment: this.selectedAppointmentDate,
        start: this.start.toString(),
        size: this.itemsPerPage.toString(),
      };
      apiCall = this.dataService.GetRDDashboardLeadershipGDMPTable1(body);
    } else {
      body = {
        startDate: this.formattedStartDate + ' 00:00:00',
        endDate: this.formattedEndDate + ' 23:59:59',
        start: this.start.toString(),
        size: this.itemsPerPage.toString(),
      };
      apiCall = this.dataService.GetRDDashboardGDMPTable1(body);
    }
  
    apiCall.subscribe({
      next: (result) => {
        this.loader[0] = false;
        this.isInitialized1 = true; // Mark the request as initialized
        if (result.status === 'failed') {
          console.error('Failed to fetch GDMP data:', result.message);
        } else {
          this.lifeTimeData = result?.data?.data || [];
          this.totalRecords = result?.data?.total || this.lifeTimeData.length;
          this.totalPages = Math.ceil(this.totalRecords / this.itemsPerPage);
          let timestamp = result?.data?.lastUpdateTime?.updatedAt;
          this.lastUpdateTime = timestamp ? new Date(timestamp).toLocaleString() : 'No updates available';
          this.updateEnd();
        }
      },
      error: (error) => {
        this.loader[0] = false;
        this.isInitialized1 = true; // Mark the request as initialized on error
        console.error('Error fetching GDMP table data:', error);
      }
    });
  }

  resetScroll1() {
    if (this.tableContainer1) {
      this.tableContainer1.nativeElement.scrollTop = 0;
      this.tableContainer1.nativeElement.scrollLeft = 0;
    }
  }

  resetScroll2() {
    if (this.tableContainer2) {
      this.tableContainer2.nativeElement.scrollTop = 0;
      this.tableContainer2.nativeElement.scrollLeft = 0;
    }
  }
  
  getGDMPTable2Data() {
    this.loader[1] = true;
    let body: any;
    let apiCall: any;
  
    if (this.componentName === 'leadership') {
      body = {
        startDate: this.formattedStartDate + ' 00:00:00',
        endDate: this.formattedEndDate + ' 23:59:59',
        providers: this.selectedProviders,
        appointment: this.selectedAppointmentDate,
        start: this.start1.toString(),
        size: this.itemsPerPage1.toString(),
      };
      apiCall = this.dataService.GetRDDashboardLeadershipGDMPTable2(body);
    } else {
      body = {
        startDate: this.formattedStartDate + ' 00:00:00',
        endDate: this.formattedEndDate + ' 23:59:59',
        start: this.start1.toString(),
        size: this.itemsPerPage1.toString(),
      };
      apiCall = this.dataService.GetRDDashboardGDMPTable2(body);
    }
  
    apiCall.subscribe({
      next: (result) => {
        this.loader[1] = false;
        this.isInitialized2 = true; // Mark the request as initialized
        if (result.status === 'failed') {
          console.error('Failed to fetch GDMP data:', result.message);
        } else {
          this.lifeTimeData1 = result?.data?.data || [];
          this.totalRecords1 = result?.data?.total || this.lifeTimeData1.length;
          this.totalPages1 = Math.ceil(this.totalRecords1 / this.itemsPerPage1);
          let timestamp = result?.data?.lastUpdateTime?.updatedAt;
          this.lastUpdateTime = timestamp ? new Date(timestamp).toLocaleString() : 'No updates available';
          this.updateEnd1();
        }
      },
      error: (error) => {
        this.loader[1] = false;
        this.isInitialized2 = true; // Mark the request as initialized on error
        console.error('Error fetching GDMP table data:', error);
      }
    });
  }
fetchProviders(check): void {
  let body = {
    startDate: this.formattedStartDate + ' 00:00:00',
    endDate: this.formattedEndDate + ' 23:59:59',
  };

  this.dataService.GetProviderCancellation(body).subscribe({
    next: (result) => {
      this.dropdownLoader[0] = false;
      if (result.status === 'failed') {
        console.error('Failed to fetch provider data:', result.message);
      } else {
        this.providers = result.data.map(item => ({ ...item, checked: true }));
        this.selectedProviders = this.providers.map(item => item.name); 
        if(check)
        {
          this.getGDMPTable1Data();
          this.getGDMPTable2Data();
        }
      }
    },
    error: (error) => {
      this.dropdownLoader[0] = false;
      console.error('Error fetching provider data:', error);
    }
  });
}
fetchNextAppointmentDate(): void {
  this.dropdownLoader[1] = true; 

  let body = {
    startDate: this.formattedStartDate + ' 00:00:00',
    endDate: this.formattedEndDate + ' 23:59:59',
  };

  this.dataService.GetNextApptDate(body).subscribe({
    next: (result) => {
      this.dropdownLoader[1] = false;
      if (result.status === 'failed') {
        console.error('Failed to fetch next appointment data:', result.message);
      } else {
        this.appointment = result.data.map(item => ({ ...item, checked: true })); 
        this.selectedAppointmentDate = this.appointment.map(item => item.name);

        this.getGDMPTable1Data();
        this.getGDMPTable2Data();
      }
    },
    error: (error) => {
      this.dropdownLoader[1] = false; 
      console.error('Error fetching next appointment data:', error);
    }
  });
}

  onDateChange(event: any) {
    if (this.isInitialLoad) {
      this.formattedStartDate = event.startDate;
      this.formattedEndDate = event.endDate;
      this.start = 1;
      this.currentPage = 1;
      this.start1 = 1;
      this.currentPage1 = 1;
      if (this.componentName === 'leadership') {
   this.fetchProviders(true);
   this.fetchNextAppointmentDate()
      }
      else
      {
        this.getGDMPTable1Data();
        this.getGDMPTable2Data();
      }
    }
    this.isInitialLoad = true;
    this.resetScroll1();
    this.resetScroll2();
  }
  updatePaginatedData() {
    this.start = (this.currentPage - 1) * this.itemsPerPage + 1;
    this.end = Math.min(this.start + this.itemsPerPage - 1, this.totalRecords);
    this.getGDMPTable1Data();
   
  }
  
  onPageChange(newPage: number) {
    if (newPage >= 1 && newPage <= this.totalPages) {
      this.currentPage = newPage;
      this.updatePaginatedData(); 
      this.resetScroll1();
    }
  }
  
  onPageSizeChange(newPageSize: number) {
    this.itemsPerPage = newPageSize;  
    this.currentPage = 1;             
    this.totalPages = Math.ceil(this.totalRecords / this.itemsPerPage);
    this.updatePaginatedData();  
  }
  updatePaginatedData2() {
    this.start1 = (this.currentPage1 - 1) * this.itemsPerPage + 1;
    this.end1 = Math.min(this.start1 + this.itemsPerPage1 - 1, this.totalRecords1);
    this.getGDMPTable2Data();
   
  }
  onPageChangeTable2(event: number): void {
    this.currentPage1 = event;
    this.start1 = (this.currentPage1 - 1) * this.end1;
this.updatePaginatedData2()
this.resetScroll2();
  }

  onPageSizeChangeTable2(event: number): void {
    this.end1 = event;
    this.currentPage1 = 1; 
 this.updatePaginatedData2()
  }
  updateEnd() {
    this.end = Math.min(this.currentPage * this.itemsPerPage, this.totalRecords);
  }
  updateEnd1() {
    this.end1 = Math.min(this.currentPage1 * this.itemsPerPage1, this.totalRecords1);
  }
  onSelectedItemsChange(selectedItems: any[], check) {
     check ?this.selectedProviders = selectedItems : this.selectedAppointmentDate = selectedItems;
     this.start = 1;
     this.currentPage = 1;
     this.start1 = 1;
     this.currentPage1 = 1;
     this.updateEnd();
     this.updateEnd1();
     this.getGDMPTable1Data();
     this.getGDMPTable2Data();
     this.resetScroll1();
     this.resetScroll2();
      }
    
      formatDate(date: Date): string {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
      }
  getColumnClass(columnName: string): string {
    switch (columnName) {
      case 'Client Name': return 'col-client-name';
      case 'Provider': return 'col-provider';
      case 'Appt Date/Time': return 'col-appt-date';
      case 'Next Appt/Date': return 'col-next-appt';
      case 'Unique Id': return 'col-unique-id';
      default: return '';
    }
  }
  exportRDvsNonRDCSV() {
    let body;
    
    // Define the body based on the component type
    if (this.componentName === 'leadership') {
      body = {
        startDate: this.formattedStartDate + ' 00:00:00',
        endDate: this.formattedEndDate + ' 23:59:59',
        providers: this.selectedProviders,
        appointment: this.selectedAppointmentDate,
        start: this.start.toString(),
        size: this.itemsPerPage.toString(),
      };
      this.dataService.ExportRDDashboardLeadershipGDMPTable1(body).subscribe({
        next: (result) => this.handleCSVDownload(result),
        error: (error) => this.handleCSVError(error),
      });
    } else {
      body = {
        startDate: this.formattedStartDate + ' 00:00:00',
        endDate: this.formattedEndDate + ' 23:59:59',
        start: this.start.toString(),
        size: this.itemsPerPage.toString(),
      };
      this.dataService.ExportRDDashboardGDMPTable1(body).subscribe({
        next: (result) => this.handleCSVDownload(result),
        error: (error) => this.handleCSVError(error),
      });
    }
  }
  
  // CSV Download Handler
  handleCSVDownload(result: any) {
    if (result && result.status === 'success') {
      const fileUrl = result?.data?.url;
      if (fileUrl) {
        const a = document.createElement('a');
        a.href = fileUrl;
        a.download = 'rd_dashboard.csv'; // Change the file name if needed
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        console.error('No URL provided for the CSV download');
      }
    } else {
      console.error('Failed to download CSV:', result?.message);
    }
  }
  
  // Error Handler
  handleCSVError(error: any) {
    console.error('Error downloading CSV:', error);
  }
  
  exportRDvsNonRDCSVTable2() {  
    let body;
    
    // Define the body based on the component type
    if (this.componentName === 'leadership') {
      body = {
        startDate: this.formattedStartDate + ' 00:00:00',
        endDate: this.formattedEndDate + ' 23:59:59',
        providers: this.selectedProviders,
        appointment: this.selectedAppointmentDate,
        start: this.start.toString(),
        size: this.itemsPerPage.toString(),
      };
      this.dataService.ExportRDDashboardLeadershipGDMPTable2(body).subscribe({
        next: (result) => this.handleCSVDownload1(result),
        error: (error) => this.handleCSVError1(error),
      });
    } else {
      body = {
        startDate: this.formattedStartDate + ' 00:00:00',
        endDate: this.formattedEndDate + ' 23:59:59',
        start: this.start.toString(),
        size: this.itemsPerPage.toString(),
      };
      this.dataService.ExportRDDashboardGDMPTable2(body).subscribe({
        next: (result) => this.handleCSVDownload1(result),
        error: (error) => this.handleCSVError1(error),
      });
    }
  }
  
  // CSV Download Handler (same as before)
  handleCSVDownload1(result: any) {
    if (result && result.status === 'success') {
      const fileUrl = result?.data?.url;
      if (fileUrl) {
        const a = document.createElement('a');
        a.href = fileUrl;
        a.download = 'rd_dashboard_table2.csv'; // Change the file name if needed
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        console.error('No URL provided for the CSV download');
      }
    } else {
      console.error('Failed to download CSV:', result?.message);
    }
  }
  
  // Error Handler (same as before)
  handleCSVError1(error: any) {
    console.error('Error downloading CSV:', error);
  }
  


}
