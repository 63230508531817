<label class="form-label fw-bold">{{ filterName }} </label>
<div class="custom-dropdown manage-border" (click)="toggleDropdown()" appOutsideClick
  (appOutsideClick)="dropdownOpen = false">
  <div class="selected-values d-flex gap-2">
      <span class="badge-EAF2F3 font-15" *ngIf="selectedItems.length > 0">
          {{ selectedItems[0] }}
      </span>
      <ng-container *ngIf="selectedItems.length >= 2">
        <span> + {{ selectedItems.length - 1 }}</span>
      </ng-container>
      <span class="text-muted font-15" *ngIf="selectedItems?.length === 0 && items?.length > 0">
        No Filter Selected
      </span>
      <span class="text-muted font-15" *ngIf="items?.length === 0">
        No Data Found!
      </span>
    </div>

  <!-- Dropdown List -->
  <div class="collapsed-dropdown" *ngIf="dropdownOpen && items?.length > 0">
    <div class="list-group dropdown-scroll" (scroll)="onScroll($event)">
      <!-- Search Bar -->
      <a href="javascript:void(0)" class="list-group-item list-group-item-action p-0">
        <input type="text" class="form-control" placeholder="Search..." [(ngModel)]="searchTerm"
          (keyup)="onSearchTermChange()" (click)="stopPropagation($event)" />
      </a>

      <!-- Select All Option -->
      <a href="javascript:void(0)" class="list-group-item list-group-item-action p-0">
        <label class="model-dropdowns d-flex justify-content-between flex-fill"
          (click)="stopPropagation($event)">
          <div>
            <input for="selectAll" class="form-check-input me-1" type="checkbox" [checked]="isAllSelected()" (click)="toggleAll(!isAllSelected())" />
            <label id="selectAll" (click)="toggleAll(!isAllSelected())">Select All</label>
          </div>
          <span *ngIf="filteredItems?.length>0" class="text-muted">{{'('+filteredItems?.length+')'}}</span>
        </label>
      </a>

      <!-- Individual Items -->
      <a *ngFor="let item of displayedItems; let i = index" href="javascript:void(0)" 
        class="list-group-item list-group-item-action p-0">
        <div class="model-dropdowns w-100 ms-2 d-flex gap-1" (click)="stopPropagation($event)">
          <input class="me-1 form-check-input" type="checkbox" id="checkbox-{{i}}" [checked]="item.checked" (click)="stopPropagation($event)" (change)="onItemChange($event, item)" />
          <label class="form-check-label" [for]="'checkbox-' + i" (click)="stopPropagation($event)">{{ item.name }}</label>
        </div>
        <span class="custom-text" (click)="selectOnly(item)">Only</span>
      </a>
    </div>
  </div>
</div>